<template>
  <div style='padding: 5px'>
    <div v-for='(row,index) in rows' :key='index'>

      <!--      排期单击事件：查看排期详情 @click.stop="showDetail(row)"  @contextmenu.prevent.stop='rightClick($event,row)'-->
      <div v-if='index<limit' :class="['plan-item',progressStatusMap[row.progress_status]]" draggable='true'
           @dragend='dragend' @dblclick='handleDetail(row)' @dragstart='dragstart(row)'
           @contextmenu.prevent.stop='rightClick($event,row)' >
        <el-tooltip class="item" effect="light" :content="`发起人：${row.leader_name},美护选品:${row.lb_product_count},服饰选品：${ row.lb_dress_selection_count}`" placement="top">
        <span style="height: 30px;display: block;line-height: 30px">
                    <img v-if='platfrormsIcon(row.platform_code)' :src='platfrormsIcon(row.platform_code)'
                         style='width: 16px;height: 16px;vertical-align:middle'/> {{ row.nickname || '' }}
          {{ row.platform_name || '' }}        </span>
<!--        <span :title='`发起人：${row.leader_name}`'>-->
<!--          <img v-if='platfrormsIcon(row.platform_code)' :src='platfrormsIcon(row.platform_code)'-->
<!--               style='width: 14px;height: 14px' /> {{-->
<!--            row.platform_name || ''-->
<!--          }}  【{{ row.nickname || '' }}】-->
<!--             <span :class="[ row.lb_product_count?'selected':'unselect']">{{ row.lb_product_count || 0 }}</span> /-->
<!--        <span :class="[ row.lb_dress_selection_count?'selected':'unselect']">{{-->
<!--            row.lb_dress_selection_count || 0-->
<!--          }}</span>-->
<!--        </span>-->
        </el-tooltip>
<!--        <span :class="[ row.selected_qty?'selected':'unselect']">({{ row.selected_qty || 0 }})</span>-->
      </div>

    </div>
    <Contextmenu :menu-data='menuData' :id='currentId' @change='handleCmd' />
  </div>
</template>

<script>
import Contextmenu from './Contextmenu'

export default {
  name: 'CalendarPanel',
  components: { Contextmenu },
  props: {
    item: {
      type: Object,
      default() {
        return { data: {} }
      }
    },
    showLimit: {
      type: Boolean,
      default() {
        return true
      }
    },
    platforms: {
      type: [Object, Array],
      default() {
        return { data: {} }
      }
    }
  },
  computed: {
    rows() {
      return this.item ? this.item.data : []
    },
    limit() {
      if (this.showLimit) {
        return 3
      } else {
        return this.rows.length
      }
    },
    currentId() {
      return this.currentRow ? this.currentRow.id : null
    },
    progressStatusMap() {
      let map = {}
      this.progressStatusOptions.forEach((item) => {
        map[item.value] = item.class_name
      })
      return map
    },


  },
  data() {
    return {
      menuData: {
        visible: false,
        top: 0,
        left: 0
      },
      currentRow: {},
      dragData: {},
      progressStatusOptions: [
        { label: '新创建', value: 1, color_value: '#ffffff', class_name: 'status-new' },
        { label: '已发需求', value: 2, color_value: '#67C23A', class_name: 'status-launch' },
        { label: '初选完成', value: 3, color_value: '#409EFF', class_name: 'status-original' },
        { label: '确认完成', value: 4, color_value: '#E6A23C', class_name: 'status-ack' },
        { label: '锁定表格', value: 5, color_value: '#F56C6C', class_name: 'status-lock' }
      ],
      currentItem: {},

    }

  }
  ,
  mounted() {
  },
  methods: {
    platfrormsIcon(code) {
      let obj = this.platforms.find(value => value.code === code)
      return obj ? obj.logo_url : null
    },
    dragend() {
      if (this.dragData != {}) {
        this.$emit('handleDrag', this.dragData)
      }
    },
    dragstart(data) {
      this.dragData = data
    },
    rightClick(e, row) {
      this.currentRow = row
      // this.$notify.info(`右键菜单:${e.clientY}`)
      e.preventDefault()//阻止元素发生默认的行为
      const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
      const offsetTop = this.$el.getBoundingClientRect().top // container margin top

      this.menuData = {
        visible: true,
        left: e.clientX - offsetLeft,
        top: e.clientY - offsetTop
      }
      document.addEventListener('click', this.closeMenu) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
    },
    //右键菜单关闭
    closeMenu(e) {
      //未点击在 菜单上，则关闭
      if (e.target.className.indexOf('contextmenu__item') === -1) {
        this.menuData.visible = false
        document.removeEventListener('click', this.closeMenu)
      }
    }
    ,
    handleCmd(cmd) {
      this.$emit('handleCmd', cmd, this.currentRow)
    },
    handleDetail(currentRow) {
      this.$emit('handleCmd', 'detail', currentRow)
    },
    openMenu(e) {
      // this.$notify.info(`右键菜单:${e.clientY}`)
      e.preventDefault()//阻止元素发生默认的行为
      const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
      const offsetTop = this.$el.getBoundingClientRect().top // container margin top

      this.menuData = {
        visible: true,
        left: e.clientX - offsetLeft,
        top: e.clientY - offsetTop
      }
      document.addEventListener('click', this.closeMenu) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
    },

  }
}
</script>

<style lang="scss" scoped>
@import "../style/main.scss";

.plan-item {
  line-height: 1.1;
  border: #7d8086 1px solid;
  margin-bottom: 2px;
  cursor: pointer;
  width: 98%;
  overflow-x: hidden;
  text-align: center;
  border-radius: 3px;
  margin-left: 1%;
  white-space: nowrap;
  padding: 2px 0;
  font-size: 14px;
}

.plan-item:hover {
  /*background-color: #FFEAF1;*/
  font-size: 16px;
  /*padding: 1px 0;*/
}

.unselect {
  color: #F56C6C;
}

.status-new {
  background-color: $--color--white;
}

.status-launch {
  background-color: $--status-launch-bg;
}

.status-original {
  background-color: $--status-original-bg;
  color: #fefefe;
}

.status-ack {
  background-color: $--status-ack-bg;
  color: #fefefe;
}

.status-lock {
  background-color: $--status-lock-bg;
  color: #fefefe;
}
</style>
