<template>
  <div class="radius_bg">
    <div class="time_selection ">
      <div class="selected">
        <div class="el-icon-arrow-left time_selection_button" @click="handleYm('previous')"></div>
        <span>
       {{ searchCondition.ym }}
     </span>
        <div class="el-icon-arrow-right time_selection_button" @click="handleYm('next')"></div>
      </div>
    </div>
    <div class='el-calendar__body'>
      <table cellspacing='0' cellpadding='0' class='el-calendar-table'>
        <thead>
        <th class='week'>周一</th>
        <th class='week'>周二</th>
        <th class='week'>周三</th>
        <th class='week'>周四</th>
        <th class='week'>周五</th>
        <th class='week weekend'>周六</th>
        <th class='week weekend'>周日</th>
        </thead>
        <tbody>

        <tr class='el-calendar-table__row' v-for='(row,k)  in calendarData' :key='k' @dragover='allowDrop'>
          <td :class="[item.class_name, row.show_date?'week':'']"
              @drop='dragend(item,j,k)' v-for='(item,j)  in row.weekDays'
              :key='`${k}_${j}`'
          >
            <div class='calendar-panel'>
              <div class='calendar-header' @contextmenu.prevent.stop='openMenu($event,item)'>
                <!--                <span v-if='item.fullDate' :class="['dates', todayDate===item.fullDate?'today':'']">-->
                <!--                  {{ $utils.parseTime(item.fullDate, '{y}/{m}/{d}') }}-->
                <!--                </span>-->
                <!--                <span v-else class='dates'>{{ item.num }}</span>-->
                <span v-if='item.fullDate' :class="['dates', todayDate===item.fullDate?'today':'']">
                  {{ item.num }}
                  <!--                  {{ $utils.parseTime(item.fullDate, '{y}/{m}/{d}') }}-->
                </span>
                <span v-else class='dates' style="color: #C0C4CC">{{ item.num }}</span>
              </div>

              <div class='calendar-body'>
                <div v-if='item.fullDate'>
                  <!--                    {{ item}}-->
                  <CalendarPanel :platforms='platforms' @handleDrag='handleDrag' :item='item' :show-limit='true'
                                 @handleCmd='handleCmd'
                  />
                </div>
                <div v-else>
                  /
                </div>
              </div>

              <div class='calendar-footer show-more'>
                <el-popover
                    placement='right'
                    width='300'
                    trigger='click'>
                  <div>
                    <CalendarPanel :platforms='platforms' :item='item' :show-limit='false' @handleCmd='handleCmd'/>
                  </div>
                  <el-button size='mini' type='text' slot='reference'
                             v-show='item.data&&item.data.length>3'
                             style='width: 100%;text-align: center;font-size: 12px;'>
                      <span class='show-all-btn' style='width: 100%'> 查看更多({{
                          item.data && item.data.length
                        }})</span>
                  </el-button>
                </el-popover>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <ContextmenuTop :menu-data='menuData' @change='handleAdd'/>
    <!--    <el-dialog-->
    <!--      width='800px'-->
    <!--      title='钉钉消息通知'-->
    <!--      :visible.sync='showNotify'-->
    <!--      append-to-body>-->
    <!--      <NotifySend :id='editData.id' :action.sync='action' @close='close' />-->
    <!--    </el-dialog>-->
  </div>
</template>

<script>

import ContextmenuTop from './ContextmenuTop'
import CalendarPanel from './CalendarPanel'
import NotifySend from '@/pages/lb/NotifySend'

export default {
  name: 'ScheduleCalendar',
  components: { CalendarPanel, ContextmenuTop, NotifySend },
  props: {
    LbType: {
      type: [Number, String],
      default() {
        return null
      }
    },
    searchCondition: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  watch: {
    // searchCondition: {
    //   deep: true,
    //   handler() {
    //     this.handleQuery()
    //   }
    // }
  },
  data() {
    return {
      todayDate: null,
      menuData: {
        visible: false,
        top: 0,
        left: 0
      },
      currentItem: {},
      currentRow: {},
      editData: {},
      dragendTime: '',
      showNotify: false,
      action: 'edit',
      dragendWeek: '',
      dataList: [],
      platforms: []
    }
  },
  computed: {

    currentId() {
      return this.currentRow ? this.currentRow.id : null
    },

    //本月的数据转为对象，对象的键为日期， date=>[data1,data2]
    dailyData() {
      let dataMap = {}
      this.dataList.forEach((item) => {
        const date = this.$utils.parseTime(item.date, '{y}-{m}-{d}')
        if (!dataMap[date])
          dataMap[date] = []

        dataMap[date].push(item)

      })
      return dataMap
    },
    calendarData() {
      let dataList = []
      if (this.searchCondition.ym) {
        //根据当前月份的第一天，计算出当月月份日历的数据
        const currentMonthFirstDay = `${this.searchCondition.ym}-01`
        const days = this.$utils.calcCalendarDates(currentMonthFirstDay)

        let daysArr = this.$utils.array_chunk(days, 7)
        for (let j in daysArr) {
          // eslint-disable-next-line
          let weekDays = daysArr[j]
          // eslint-disable-next-line no-unused-vars
          weekDays.forEach((item) => {
            item.data = item.fullDate ? this.dailyData[this.$utils.parseTime(item.fullDate, '{y}-{m}-{d}')] : []
            // item.show_all = true
          })
          dataList.push({ hidden_row: false, show_date: true, weekDays: weekDays, week: j })
        }
      }
      return dataList
    }
  },
  created() {
    this.todayDate = this.getLastDays(0)
    this.getPlatformAl()
  },
  methods: {
    handleYm(type) {
      console.log('点了', this.searchCondition.ym)
      let date = this.searchCondition.ym.split('-')
      let year = date[0]
      let month = date[1]
      let newYm

      if (type === 'next') {
        if (Number(month) !== 12) {
          newYm = `${year}-${Number(month) + 1}`
        } else newYm = `${Number(year) + 1}-01`
      } else {
        if (Number(month) !== 1) {
          newYm = `${year}-${Number(month) - 1}`
        } else newYm = `${Number(year) - 1}-12`
      }
      this.$emit('update:searchCondition', { ym: newYm })
      this.handleQuery()

    },
    async getPlatformAl() {
      let { list } = await this.$api.getPlatformAll({ status: 1 })
      this.platforms = list
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.ym) {
        condition.ym = this.searchCondition.ym
      }

      if (this.searchCondition.artist_id) {
        condition.artist_id = this.searchCondition.artist_id
      }
      if (this.searchCondition.progress_status) {
        condition.progress_status = this.searchCondition.progress_status
      }
      if (this.searchCondition.leader_id) {
        condition.leader_id = this.searchCondition.leader_id
      }
      if (this.searchCondition.leader_name) {
        condition.leader_name = this.searchCondition.leader_name
      }
      //区分选品类型
      if (this.LbType) {
        condition.type = this.LbType
      }
      return condition
    },
    async handleQuery() {
      // this.$notify.info('查询')
      this.loading = true
      // this.$notify.info('查询')
      setTimeout(async () => {
        let searchCondition = this.handleSearchCondition()
        Object.assign(searchCondition, { order: this.orderSort })
        let { list } = await this.$api.getLbScheduleMonthList(searchCondition)
        this.dataList = list

        this.$emit('update:total', Array.isArray(list) ? list.length : 0)
      }, 600)
    },

    allowDrop(ev) {
      ev.preventDefault()
    },
    async dragend(data, j, k) {
      this.dragendTime = data.fullDate
      this.dragendWeek = { data: data, index: j, week: k }
    },
    handleDrag(data) {
      this.editData = data
      this.editDrag()
    },
    async editDrag() {
      if (this.dragendTime) {
        let newStart = this.dragendTime + ' ' + this.editData.start_at.substring(this.editData.start_at.indexOf(' ') + 1)
        let newEnd = this.dragendTime + ' ' + this.editData.end_at.substring(this.editData.start_at.indexOf(' ') + 1)
        this.editData.start_at = newStart
        this.editData.end_at = newEnd
        this.editData.timeRange = [newStart, newEnd]
        let info = await this.$api.saveLbSchedule(this.editData)
        if (info) {
          this.$notify.success('修改直播排期日期成功')
          // this.$confirm('保存成功, 是否钉钉通知?', '提示', {
          //   confirmButtonText: '立即通知',
          //   cancelButtonText: '暂不通知',
          //   type: 'warning'
          // }).then(() => {
          //   this.showNotify = true
          // }).catch(() => {
          //   this.$message({
          //     type: 'info',
          //     message: '暂不通知'
          //   })
          //   this.close()
          // })
        }
        this.$emit('dragEdit', true)
      } else {
        this.$message({
          type: 'info',
          message: '日期错误，请选择正确日期'
        })
      }
    },
    close() {
      this.showNotify = false
      this.$emit('update:visible', false)
    },
    getLastDays(days) {
      //几天前的时间
      let day1 = new Date()
      day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000 * days)
      return day1.getFullYear() + '-' + (day1.getMonth() + 1) + '-' + day1.getDate()
    },
    selectProduct(row) {
      //跳转到选品页面，并携带排期ID
      this.$emit('selectProduct', row)
    },
    openMenu(e, item) {
      if (item.fullDate) {
        this.currentItem = item
        e.preventDefault()//阻止元素发生默认的行为
        this.menuData = {
          visible: true,
          left: e.pageX || e.clientX,
          top: e.pageY || e.clientY
        }
        document.addEventListener('click', this.closeMenu) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
      }
    },
    //右键菜单关闭
    closeMenu(e) {
      //未点击在 菜单上，则关闭
      if (e.target.className.indexOf('contextmenu__item') === -1) {
        this.menuData.visible = false
        document.removeEventListener('click', this.closeMenu)
      }
    },
    // eslint-disable-next-line no-unused-vars
    handleAdd(cmd) {
      this.$emit('handleCmd', cmd, this.currentItem)
    },
    handleCmd(cmd, row) {
      this.$emit('handleCmd', cmd, row)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../style/main.scss";

.time_selection {
  width: 100%;
  height: 50px;
  //border: 1px solid red;

  .selected {
    //border: 1px solid red;
    width: 300px;
    text-align: center;
    margin: 0 auto;
    font-size: 26px;
    line-height: 50px;
    color: $--text-color-gray !important;

    > span {
      font-size: 26px;
      margin: 0 30px;
      user-select: none;
    }

    .time_selection_button {
      cursor: pointer;
    }
  }

  .show_status {
    //display: inline-block;
    width: 300px;
    border: 1px solid red;
    float: right;
  }
}

.dates {
  vertical-align: middle;
  text-align: center;
  color: lighten($--color--black, 45%);
  font-size: 20px;
  margin-top: 10px;
  display: block;
}


.calendar-panel {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  /*padding: 0;*/
}

.calendar-header {
  background-color: $--color--white !important;
  text-align: center;
  color: $--text-color-gray !important;
}

.calendar-body {
  /*border: #ff3176 1px dashed;*/
  background-color: #fff !important;
  //color: lighten($--color--black,45%);
  width: 100%;
  /*border: 1px solid #001aff;*/
  margin: auto;
  display: flex;
  flex-direction: column;
  height: 140px;
  min-height: 120px;
  overflow-y: scroll;
  vertical-align: middle;
  text-align: center;
  padding: 0;
  /*z-index: 9;*/
  /*border: #ff3176 1px dashed;*/
}

.calendar-footer {
  /*background-color: #0C0C0C !important;*/
  /*color: #0C0C0C !important;*/
  line-height: 1;
  height: 22px;
  /*z-index: 10;*/
}

.show-more {
  position: absolute;
  bottom: 0;
  width: 100%;
  cursor: pointer;
  padding: 1px;
  /*background-color: #0C0C0C !important;*/
  /*border: #ff3176 1px dashed;*/
  /*background-color: #ff3176;*/
  opacity: 0.7;
}

.week {
  color: $--color--black;
  background-color: $--calendar-bg;
  font-size: 1.2em;
}

.weekend {
  background-color: $--calendar-week-bg;
}

.show-all-btn:hover {
  background-color: #F56C6C;
  color: #fefefe;
  font-size: 14px;
  padding: 3px 10px;
  border-radius: 3px;
}
</style>
